<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
      <div>
          <div>
            <h1 class="logo-name">
              <img src="/img/brand_logo.png" border="0" alt="LugStay" />
            </h1>
          </div>

          <!-- <h3>Welcome to grip</h3> -->
          <!-- <p>Perfectly designed and precisely prepared admin theme with over 50 pages with extra new web app views. -->
              <!--Continually expanded and constantly improved Inspinia Admin Them (IN+)-->
          <!-- </p> -->
          <!-- <p>Login in. To see it in action.</p> -->

          <form class="m-t" role="form" @submit.stop="onSubmit">
              <div class="form-group">
                  <input type="text" ref="admin-id" class="form-control" placeholder="Admin ID" autocapitalize="off" autocomplete="off" required="" v-model="formData.admin_id"  />
              </div>
              <div class="form-group">
                  <input type="password" class="form-control" placeholder="Password" autocapitalize="off" autocomplete="off" required="" v-model="formData.admin_password"  />
              </div>

              <button type="submit" class="btn btn-primary block full-width m-b">Login</button>

              <!-- <a href="#"><small>Forgot password?</small></a> -->
              <!-- <p class="text-muted text-center"><small>Do not have an account?</small></p> -->
              <!-- <a class="btn btn-sm btn-white btn-block" href="register.html">Create an account</a> -->
          </form>
          <p class="m-t"> <small>Bluewhale Company. &copy; 2018</small> </p>
      </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  data() {
    return {
      formData: {
        admin_id: config.mode !== 'production' ? 'master' : '',
        admin_password: config.mode !== 'production' ? 'lugstay9727' : ''
      }
    }
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault()

      this.$store.dispatch("session/login", this.$data.formData).then(({error, data}) => {
          if (error) {
            this.$toast.error(error.message)
            return
          }

          this.$router.push('/dashboard')
        })
        .catch(e => {
          this.$toast.error(e.message)
        })

      return false
    }
  }
}
</script>
